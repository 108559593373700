<template>
  <div id="category">
    <!-- Logo -->
    <section id="restaurant-logo">
      <the-logo />
    </section>
    <!-- End Logo -->
    <!-- Category -->
    <section id="categories-container">
      <category-item
        v-for="category in activeCategories"
        class="category"
        :key="category.id"
        :category="category"
        @click="onCLickCategory(category.id)"
      >
      </category-item>
    </section>

    <the-footer />
  </div>
</template>

<script>
import CategoryItem from './CategoryItem'
import TheFooter from '@/components/TheFooter'
import TheLogo from '@/components/TheLogo'
export default {
  components: {
    CategoryItem,
    TheFooter,
    TheLogo
  },
  computed: {
    activeCategories() {
      if (this.$store.state.menu.menuData) {
        return this.$store.state.menu.menuData
      } else {
        return []
      }
    }
  },
  methods: {
    gotoCategoryDetail(cateId) {
      this.$router.push({ path: '/category/' + cateId })
    },
    onCLickCategory(cateId) {
      this.gotoCategoryDetail(cateId)
    }
  }
}
</script>

<style lang="scss" scoped>
#category {
  color: white;
  width: 100%;
}

/* Logo. */
#restaurant-logo {
  background: var(--foreground);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  color: black;
  margin-top: 32px;
}

.restaurant-logo__title {
  font-weight: 700;
  font-size: 1.4em;
  align-self: center;
  padding: 10px;
}
.restaurant-logo__description {
  line-height: 0em;
  align-self: center;
}

#restaurant-logo > img {
  max-height: 150px;
  max-width: 100%;
  align-self: center;
}
/* EndLogo. */

/* categories container. */
#categories-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
/* end categories container. */

#categories-footer {
  padding: 3em;
  min-height: 200px;
}

#categories-footer {
  font-size: 1.2em;
  text-align: center;
  color: var(--dark);
}

#categories-footer > .font-1 {
  font-size: 1.2em;
}

#categories-footer > .font-2 {
  font-size: 0.9em;
}

#categories-footer > .font-3 {
  font-size: 0.6em;
}

#categories-footer > .font-4 {
  font-size: 0.5em;
}

@media (max-width: 768px) {
  #restaurant-logo > img {
    height: auto;
  }
}
</style>
