import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueObserveVisibility from 'vue-observe-visibility'
import VueProgressiveImage from 'vue-progressive-image'
// import VueLazyload from 'vue-lazyload'

Vue.use(VueObserveVisibility, {
  blur: 5,
  delay: 2000,
  cache: true
})
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   attempt: 1
// })

Vue.use(VueProgressiveImage)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
