import Vue from 'vue'
import Vuex from 'vuex'
import menu from './menu.module'
import api from './api.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    menu,
    api
  }
})
