/* eslint-disable */
let json = require('./menu-data.json')
/* eslint-enable */
const menuModule = {
  state: {
    menuData: []
  },
  getters: {
    getCategoryById: state => cateId => {
      return state.menuData.filter(category => {
        return parseInt(category.id) === parseInt(cateId)
      })
    }
  },
  mutations: {
    UPDATE_MENU_DATA(state, dataMenu) {
      state.menuData = dataMenu
    }
  },
  actions: {
    fetchMenu(content) {
      content.commit('UPDATE_MENU_DATA', json.datas)
    }
  },
  modules: {}
}

export default menuModule
